import { styled, css, utilities } from '@creditas/stylitas'
import tokens from '@creditas/tokens'

const paragraph = ({ theme }) => css`
  ${utilities.typography.paragraph(theme)};
`

const Box = styled.div`
  width: 100%;

  .deductions-container {
    margin-top: 24px;
    border: 1px solid ${tokens.bColorneutral03};
    border-radius: 8px;
    padding: 24px 16px;

    @media (max-width: 545px) {
      margin-top: 8px;
    }

    .list-deductions {
      ${paragraph}

      .item-list {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        color: ${tokens.bColorneutral07};
        margin-bottom: 16px;

        span {
          font-size: 16px;
          font-weight: 600;

          @media (max-width: 545px) {
            width: 50%;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        .box {
          color: ${tokens.bColorneutral07};
          font-size: 16px;
          font-weight: 300;

          &.isPending {
            color: ${tokens.bColorneutral06};

            svg {
              fill: currentColor;
            }
          }
        }
      }
    }

    .total {
      color: ${tokens.bColorneutral08};
      border-top: 1px solid ${tokens.bColorneutral03};
      align-items: center;
      padding-top: 24px;
      margin-top: 24px;
      display: flex;
      justify-content: space-between;
      width: 100%;

      span {
        color: ${tokens.bColorneutral07};
        font-size: 16px;
        font-weight: 600;

        @media (max-width: 545px) {
          width: 50%;
        }
      }

      strong {
        color: ${tokens.bColorneutral07};
        font-size: 16px;
        font-weight: 300;
      }
    }
  }

  .list-deductions .box,
  .total .box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .total.isPending {
    color: ${tokens.bColorneutral06};
    svg {
      fill: currentColor;
      font-size: 43px;
    }
  }
`

export { Box }
