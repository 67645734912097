import React from 'react'

import { ClientDeductions } from './ClientDeductions'
import { TranslatedErrorBoundary } from '../../components'

import { setUserContext } from '../../utils'
import tokens from '@creditas/tokens'
import { ThemeProvider } from '@creditas/stylitas'
import { createDataStructure, formatDataStructure } from './helpers'
import { RequestManager } from '../../requests'
import { DashboardData } from '../../interfaces/Dashboard'

interface Props {
  api: string
  language: string
  authToken: string
  clientId: string
  data: DashboardData
}

interface State {
  componentData: any
}

class App extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      componentData: false,
    }
  }

  componentDidCatch() {}

  componentDidMount() {
    const { api, authToken, clientId } = this.props
    RequestManager.url = api
    RequestManager.authToken = authToken
    setUserContext({ id: clientId })

    this.fetchAPIData()
  }

  async fetchAPIData() {
    const APIData = this.props.data

    const { deductions, reference_month } = APIData

    let dataStructure = createDataStructure(deductions!)
    dataStructure = {
      ...dataStructure,
      referenceMonthName: reference_month!,
    }

    const dataFormatted = formatDataStructure(dataStructure)

    this.setState({
      componentData: dataFormatted,
    })
  }

  render() {
    const { componentData } = this.state

    return (
      <TranslatedErrorBoundary>
        <ThemeProvider theme={{ ...tokens }}>
          {componentData !== false && <ClientDeductions data={componentData} />}
        </ThemeProvider>
      </TranslatedErrorBoundary>
    )
  }
}

export { App }
