import React from 'react'

import {
  BlockDivider,
  BlockDividerTitle,
  BlockDividerDescription,
} from '@creditas/block-divider'

import { Box } from './clientDeductions.style'
import { DeductionsData } from './interfaces'
import { ProductType } from '../../enums'
import { Content } from '@creditas/icons'
import { useTranslation } from 'react-i18next'

interface Props {
  data: DeductionsData
}

const ClientDeductions = ({
  data: { deductions, total, referenceMonthName },
}: Props) => {
  const { t } = useTranslation()

  const renderQuantity = (quantity: number) => {
    return quantity > 1 ? `(${quantity})` : ''
  }

  const hasDeductions = !!deductions?.length

  const renderProductType = (type: string) => {
    const key = {
      [ProductType.SalaryAdvance]: 'salaryAdvance',
      [ProductType.Discharging]: 'loan',
      [ProductType.Requesting]: 'loan',
      [ProductType.Product]: 'product',
      [ProductType.Refinancing]: 'refinancing',
    }

    return t(`productType.${key[type] || 'othersProducts'}`)
  }

  return (
    <Box>
      <BlockDivider className="block-divider">
        <BlockDividerTitle>
          {t('title')} {referenceMonthName}
        </BlockDividerTitle>
        <BlockDividerDescription>
          {hasDeductions && t('description')}
          {!hasDeductions && t('description_empty')}
        </BlockDividerDescription>
      </BlockDivider>

      {hasDeductions && (
        <div className="deductions-container">
          <div className="list-deductions">
            {deductions?.map((item, i) => {
              return (
                <div key={i} className="item-list">
                  <span>
                    {renderProductType(item.productType)}
                    {renderQuantity(item.quantity)}
                  </span>
                  <div
                    className={`box ${item.isPending ? 'isPending' : ''}`}
                    title={`${item.isPending ? 'Em análise' : ''}`}
                  >
                    {item.isPending && <Content.Clock />}
                    <strong>{item.amount}</strong>
                  </div>
                </div>
              )
            })}
          </div>

          {hasDeductions && (
            <div className="total">
              <span>{t('totalDiscounts')}</span>
              <div className="box">
                <strong>{total}</strong>
              </div>
            </div>
          )}
        </div>
      )}
    </Box>
  )
}

export { ClientDeductions }
